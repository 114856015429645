@import "~/styles/responsive.scss";

.customer_feedback {
  position: relative;
  background: var(--primary-blue-50);
  padding: 3rem 0;
  overflow: hidden;

  @include devices(md) {
    padding: 100px 0 0 0;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 600;

    @include devices(md) {
      font-size: 2rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @include devices(md) {
      gap: 3rem;
    }

    .feedbacks {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include devices(md) {
        display: grid;
        grid-template-areas:
          "f1 f2 f3"
          "f1 f2 f6"
          "f1 f5 f6"
          "f4 f5 f6"
          "f7 f5 f6"
          "f7 f5 f8"
          "f7 f5 f10"
          "f7 f9 f10";
      }

      .feedback {
        padding: 1rem;
        border-radius: 1.25rem;
        background: white;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        border: 3px solid var(--grey-800);
        box-shadow: -0.75rem 0.75rem 0px 0px var(--grey-1050);
        letter-spacing: -0.1px;

        &:nth-child(1) {
          grid-area: f1;
        }
        &:nth-child(2) {
          grid-area: f2;
        }
        &:nth-child(3) {
          grid-area: f3;
        }
        &:nth-child(4) {
          grid-area: f4;
        }
        &:nth-child(5) {
          grid-area: f5;
        }
        &:nth-child(6) {
          grid-area: f6;
        }
        &:nth-child(7) {
          grid-area: f7;
        }
        &:nth-child(8) {
          grid-area: f8;
        }
        &:nth-child(9) {
          grid-area: f9;
        }
        &:nth-child(10) {
          grid-area: f10;
        }

        .divider {
          width: 100%;
          height: 1px;
          background: var(--grey-100);
        }

        .description {
          color: var(--grey-600);
          font-size: 0.875rem;
          line-height: 1.5;
        }
      }
    }
  }

  .blur {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 296px;
    background: linear-gradient(
      180deg,
      rgba(230, 236, 251, 0) 0%,
      var(--primary-blue-50) 85%
    );
    display: none;

    @include devices(md) {
      display: block;
    }
  }
}
