.pill {
  border-radius: 4rem;
  padding: 0.25rem 0.75rem;
  border: 1px solid var(--light-blue);
  color: var(--blue);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;

  &.active {
    background-color: var(--light-blue);
  }
}
