@import "~/styles/responsive.scss";
@import "~/styles/typed";

.hero {
  padding-top: 40px;
  padding-bottom: 24px;
  overflow: hidden;
  background-color: var(--neutral-300);

  .title {
    font-weight: 700;
    font-size: 1.75rem;
    color: var(--grey-900);
    line-height: 1.3;

    @include devices(md) {
      font-size: 2.25rem;
    }
  }

  .circle_text {
    position: relative;
    z-index: 1;
  }

  .main_text {
    position: relative;
    z-index: 0;

    &:before {
      content: "";
      background: url("/svgs/hero_circle.svg") no-repeat;
      background-size: contain;
      position: absolute;
      width: 80px;
      aspect-ratio: calc(135 / 95);
      top: 60%;
      left: 60%;
      right: 0;
      z-index: -1;
      transform: translate(-50%, -50%);

      @include devices(md) {
        width: 125px;
      }
    }
  }

  .description {
    font-size: 1.125rem;
    line-height: 150%;
    color: var(--grey-600);
    font-weight: 600;
    // height: 92px;

    @include devices(lg) {
      font-size: 1.25rem;
    }

    @include devices(md) {
      // height: 96px;
    }

    @include devices(xl) {
      // height: 70px;
    }

    .typing_effect {
      color: var(--primary);

      &.en {
        @include typed(
          "automated tasks",
          "reduced support costs",
          "effortless AI integration",
          "24/7 automation customer support",
          (
            type: 0.15,
            pause-deleted: 0.4,
          ),
          (
            caret-width: 2px,
            caret-space: 2px,
            caret-speed: 1,
          )
        );
      }

      &.vi {
        @include typed(
          "tư vấn bán hàng tự động",
          "giảm chi phí nhân sự",
          "ứng dụng AI dễ dàng",
          "phản hồi khách hàng 24/7",
          (
            type: 0.15,
            pause-deleted: 0.4,
          ),
          (
            caret-width: 2px,
            caret-space: 2px,
            caret-speed: 1,
          )
        );
      }

      &:after {
        content: "_";
        border-right: none;
      }
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    text-align: left;
    // width: 100%;
    flex: 1;

    @include devices(lg) {
      padding-top: 2rem;
      // width: calc(629 / 1240 * 100%);
    }
  }

  .right {
    display: none;
    position: relative;
    // flex: 1;
    max-width: 441px;
    width: 100%;

    @include devices(lg) {
      display: block;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   aspect-ratio: calc(755 / 432);
    //   background-image: url("/svgs/hero_window.svg");
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   bottom: -27px;
    //   left: 18px;
    //   width: 552px;

    //   @include devices(lg) {
    //     width: 755px;
    //   }
    // }

    .hero_img_container {
      position: relative;
      // aspect-ratio: calc(1220 / 1888);
      aspect-ratio: calc(892 / 1400);
      max-width: 334px;
      max-height: 600px;
      // margin: auto;
      z-index: 0;
      // border: 1px solid var(--grey-200);
      border-radius: 0.75rem;
      overflow: hidden;
      user-select: none;
      border: solid 1px var(--grey-100);

      video {
        margin-top: -1px;
      }
    }

    .animated_logo {
      width: 107px;
      height: 107px;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      transform: translate(calc(72 / 156 * -100%), calc(68 / 156 * -100%));
      transform: translateX(100%);
    }
  }
}
