.download_app_button {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  .title {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--grey-400);
  }

  .icons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem 1.25rem;
  }
}
