@import "~/styles/responsive.scss";

.audience_target {
  background: var(--primary);
  padding: 4.5rem 0;
  color: white;
  position: relative;
  // z-index: 0;
  // overflow: hidden;

  &::after {
    content: "";
    background: url("/svgs/cxgenie_head.svg");
    background-size: contain;
    width: 100%;
    max-width: 269px;
    aspect-ratio: calc(469 / 382);
    position: absolute;
    right: 0;
    bottom: -2rem;
    opacity: 0.2;
    // z-index: -1;
    pointer-events: none;

    @include devices(md) {
      max-width: 300px;
    }

    @include devices(lg) {
      max-width: 469px;
    }
  }

  :global(.section-title) {
    text-align: left;
    margin-bottom: 1rem;
    color: white;
  }

  .audience_item {
    position: relative;
    border-radius: 64px;
    border: 3px solid var(--grey-50);
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    transition: all 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
      border: 3px solid var(--grey-800);
      background: var(--accent-yellow);
      color: var(--grey-800);

      .audience_popover {
        opacity: 1;
        pointer-events: unset;
      }
    }

    .audience_popover {
      position: absolute;
      left: 0;
      top: calc(100% + 0.5rem);
      background-color: white;
      border-radius: 1rem;
      padding: 1.25rem;
      display: flex;
      opacity: 0;
      flex-direction: column;
      row-gap: 1rem;
      border: 2px solid var(--grey-800);
      box-shadow: -0.5rem 0.5rem 0px 0px rgba(0, 0, 0, 0.2);
      width: 332px;
      z-index: 2;
      transition: opacity 0.25s ease-in-out;
      pointer-events: none;

      .name {
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--grey-800);
      }

      .description {
        font-size: 0.875rem;
        color: var(--grey-600);
      }

      &::before {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 100%;
        content: "";
        height: 0.5rem;
      }
    }
  }
}
