@import "~/styles/responsive.scss";

.experience {
  padding: 3rem 0;
  background-color: var(--primary);

  @include devices(lg) {
    padding: 6rem 0;
  }

  .container {
    border-radius: 1.25rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    overflow: hidden;
    display: flex;
    gap: 2rem 3.5rem;
    padding: 1.75rem 1.25rem 0 1.25rem;
    background-color: white;
    border: 3px solid var(--grey-800);
    box-shadow: -12px 12px 0px 0px #2339cf;

    @include devices(lg) {
      flex-direction: row;
      padding: 3.5rem 4rem 0 4rem;
    }

    .title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: var(--primary);
      margin-bottom: 0.5rem;

      @include devices(md) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }

    .description {
      font-size: 0.875rem;
      line-height: 1.5;
      color: var(--grey-700);
    }

    .no_credit {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 0.875rem;
      color: var(--grey-500);

      &::before {
        --size: 24px;

        content: "";
        width: var(--size);
        height: var(--size);
        background: var(--accent-green);
        clip-path: path(
          "M10.6 13.8L8.425 11.625C8.24167 11.4417 8.01667 11.35 7.75 11.35C7.48333 11.35 7.25 11.45 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.0833 16.0833 10.3167 16.175 10.6 16.175C10.8833 16.175 11.1167 16.0833 11.3 15.9L16.975 10.225C17.1583 10.0417 17.25 9.81667 17.25 9.55C17.25 9.28333 17.15 9.05 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z"
        );
        flex-shrink: 0;
      }
    }
  }
}
