@import "~/styles/responsive.scss";

.integrations {
  background-color: var(--primary);
  overflow: hidden;

  .wrapper {
    position: relative;
    max-width: 1030px;
    margin: 0 auto;
    // padding: 4.5rem 0 0 0;

    .line {
      position: absolute;
      left: 2%;
      height: 100%;
      width: 0.75rem;

      @include devices(md) {
        left: 5.5%;
      }

      &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-color: var(--primary-300);
        opacity: 0.5;
      }

      &::after {
        position: absolute;
        content: "";
        background-color: var(--accent);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        border: 3px solid var(--grey-800);
        z-index: 1;

        @include devices(md) {
          top: 39%;
        }

        @include devices(lg) {
          top: 35%;
        }
      }
    }

    .line_2 {
      position: absolute;
      border-top: 3px dashed var(--primary-300);
      top: 31.5%;
      left: 5%;
      width: 50%;

      @include devices(md) {
        width: 70%;
        top: 41%;
        left: 7.5%;
      }

      @include devices(lg) {
        width: 60%;
        top: 37%;
        left: 7.5%;
      }
    }

    .title {
      font-size: 1.5rem;
      font-weight: 600;
      color: white;
      text-align: center;
      padding-top: 3rem;
      display: block;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 324px;
      margin: 0 auto;

      @include devices(md) {
        padding-top: 4.5rem;
        font-size: 2rem;
        max-width: unset;
        margin: unset;
      }
    }

    .description {
      color: var(--grey-100);
      margin-top: 0.75rem;
      margin-bottom: 2.25rem;
      font-size: 0.875rem;
      text-align: center;

      @include devices(md) {
        font-size: 1rem;
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2rem 4rem;
      margin-bottom: 3.125rem;

      @include devices(md) {
        flex-direction: row;
      }
    }

    .button {
      border: 3px solid var(--grey-800);

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-weight: 600;
      color: white;

      &.sdk {
        position: relative;
        background-color: var(--accent-blue);
        border-radius: 0.5rem;
        font-size: 1.125rem;
        width: 163px;
        height: 51px;
        z-index: 1;

        &::after {
          position: absolute;
          content: "";
          left: 50%;
          top: calc(100% + 3px);
          border-left: 3px dashed var(--primary-300);
          height: 100px;
        }
      }

      &.react_native {
        position: relative;
        background-color: var(--accent-violet);
        border-radius: 0.5rem;
        font-size: 1.125rem;
        width: 163px;
        height: 51px;
        z-index: 1;

        &::after {
          position: absolute;
          content: "";
          left: 50%;
          top: calc(100% + 3px);
          border-left: 3px dashed var(--primary-300);
          height: 100px;
        }
      }

      &.flutter {
        position: relative;
        border-radius: 0.5rem;
        background-color: var(--orange);
        font-size: 1.125rem;
        width: 109px;
        height: 51px;
        z-index: 1;

        &::after {
          position: absolute;
          content: "";
          left: 50%;
          top: calc(100% + 3px);
          border-left: 3px dashed var(--primary-300);
          height: 100px;
        }
      }

      &.add_ons {
        position: absolute;
        top: 65%;
        left: 7.5%;
        border-radius: 4rem;
        background-color: var(--accent-pink);
        font-size: 0.875rem;
        width: 135px;
        height: 38px;
        z-index: 2;

        .icon {
          font-size: 1.125rem !important;
        }

        @include devices(md) {
          height: 51px;
          width: 181px;
          font-size: 1.125rem;
          top: 52%;
          left: 7%;

          .icon {
            font-size: 1.5rem !important;
          }
        }

        @include devices(lg) {
          top: 45.5%;
        }
      }

      &.customer_authentication {
        position: absolute;
        border-radius: 4rem;
        background-color: var(--accent-green);
        font-size: 0.875rem;
        width: 227px;
        height: 38px;
        z-index: 2;
        right: 5%;
        top: 87%;

        .icon {
          font-size: 1.125rem !important;
        }

        @include devices(md) {
          font-size: 1.125rem;
          width: 303px;
          height: 51px;
          top: 65%;
          right: 5%;

          .icon {
            font-size: 1.5rem !important;
          }
        }

        @include devices(lg) {
          top: 65%;
          left: 79.5%;
        }
      }
    }

    .image {
      position: relative;
      z-index: 1;
      height: 221px;
      object-position: -2.5rem 0;
      object-fit: none;

      @include devices(md) {
        object-fit: contain;
        object-position: unset;
        height: auto;
        border-radius: 1rem 1rem 0px 0px;
        box-shadow: -22px 22px 0px 0px rgba(0, 0, 0, 0.15);
      }
    }
  }
}
