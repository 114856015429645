@import "~/styles/responsive.scss";

.discord {
  background-color: var(--neutral-300);
  padding: 0 1rem 3.25rem;

  &_container {
    display: flex;
    gap: 2rem;
    padding: 1.75rem 1.25rem;
    border-radius: 1.25rem;
    border: 3px solid var(--grey-800);
    background-color: var(--primary-100);
    box-shadow: -0.75rem 0.75rem 0px 0px var(--grey-1000);

    @include devices(lg) {
      padding: 2.5rem 4rem;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;

      .benefits {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 0.75rem;
        background-color: rgba(0, 0, 0, 0.15);
        overflow: hidden;

        .benefit {
          position: relative;
          display: flex;
          gap: 0.5rem;
          padding: 0.75rem;

          &:first-child,
          &:nth-child(3) {
            &::before {
              position: absolute;
              height: calc(100%);
              content: "";
              width: 1px;
              right: 0;
              margin-top: -0.75rem;
              background-color: var(--grey-50);
              opacity: 0.2;
            }
          }

          &:not(:first-child, :nth-child(2)) {
            &::after {
              position: absolute;
              height: 1px;
              content: "";
              width: 100%;
              right: 0;
              margin-top: -0.75rem;
              background-color: var(--grey-50);
              opacity: 0.2;
            }
          }
        }
      }
    }

    .right {
      display: none;

      @include devices(lg) {
        display: block;
      }
    }
  }
}
