@import "~/styles/responsive.scss";

.e_commerce {
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2rem;

  @include devices(lg) {
    max-width: 629px;
  }

  .content_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.5rem;
    background-color: white;
    padding: 1.5rem;
    gap: 64px;
    overflow: hidden;
    box-shadow: -12px 12px 0px 0px #a8c0ff;
    border: 3px solid var(--grey-800);

    @include devices(lg) {
      flex-direction: row;
      padding: 1.5rem;
      border-radius: 1rem;
    }

    .title {
      color: var(--grey-900);
      font-weight: 600;
      line-height: 130%;
      font-size: 1.25rem;

      @include devices(lg) {
        font-size: 1.125rem;
      }
    }

    .subtitle {
      color: var(--grey-500);
      font-weight: 400;
      line-height: 150%;
      margin: 1rem 0 1.5rem;
      display: block;
      font-size: 0.875rem;

      @include devices(lg) {
        font-size: 0.875rem;
        margin: 4px 0 1rem;
      }
    }

    .img_container {
      width: 100%;
      max-width: 465px;
      aspect-ratio: calc(465 / 300);
      position: relative;

      .animated {
        width: calc(110 / 465 * 100%);
        aspect-ratio: 1;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, -30%);
      }
    }
  }
}
