@import "~/styles/responsive";

.demo_video {
  padding: 2.5rem 0;
  background-color: var(--neutral-300);

  @include devices(lg) {
    padding: 4.5rem 0;
  }

  .description {
    font-size: 1rem;
    line-height: 150%;
    color: var(--grey-600);
    margin-bottom: 2rem !important;

    max-width: 608px;
    margin: 0 auto;
    text-align: center;
  }

  .video_container {
    width: 100%;
    aspect-ratio: calc(16 / 9);
    max-width: 1034px;
    margin: auto;
    position: relative;

    .video {
      width: 100%;
      aspect-ratio: calc(16 / 9);
      border-radius: 1rem;
      object-fit: cover;
      object-position: center;
      background: lightgray -5.133px -1.996px / 100.69% 101.032% no-repeat;
      box-shadow: 0px 10px 48px 0px rgba(0, 0, 0, 0.08);
    }
  }
}
