.input_label {
  font-size: 0.875rem;
  line-height: 150%;
  color: var(--grey-400);
  font-weight: 400;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 150%;
}

.plan {
  --bg-gradient: linear-gradient(138deg, #72db7c 0%, #395fd6 158.44%);
  background: var(--bg-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "salt" on;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;

  &.plus {
    --bg-gradient: linear-gradient(97deg, #ff9c64 -5.63%, #ffbe15 107.57%);
  }

  &.special {
    --bg-gradient: linear-gradient(314deg, #3793ff -2.28%, #182ea3 98.98%);
  }
}

.link {
  font-weight: 600;
  color: var(--primary);
}
