@import "~/styles/responsive";

.brand {
  padding: 2.5rem 0;

  @include devices(lg) {
    padding: 6.25rem 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include devices(md) {
      row-gap: 3rem;
    }
  }

  .title {
    font-size: 1.25rem;
    color: var(--grey-800);
    font-weight: 600;

    @include devices(md) {
      text-align: center;
      font-size: 2rem;
    }
  }

  .list {
    display: grid;
    gap: 2rem;
    justify-items: center;

    @include devices(sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5rem;
    }

    @include devices(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include devices(lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      flex-wrap: wrap;
    }
  }
}
