@import "~/styles/responsive.scss";

.discord_modal {
  position: relative;
  max-width: 852px;
  max-height: 486px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  outline: none;
  border: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  right: auto;
  bottom: auto;
  padding: 1rem;

  &_container {
    position: relative;
    border-radius: 1.25rem !important;
    border: 0.125rem solid var(--grey-800);
    background-color: white;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    flex-direction: column;

    @include devices(md) {
      flex-direction: row;
    }

    .close_icon {
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      cursor: pointer;
      color: white !important;
      z-index: 1;

      @include devices(md) {
        color: var(--grey-500) !important;
      }
    }

    .left {
      position: relative;
      flex: 1;
      background-color: var(--primary-100);
      width: 100%;
      overflow: hidden;
      flex-grow: 1;

      .image {
        position: absolute;
        width: 492px;
        height: 492px;
        transform: translate(-50%, -25%);
        left: 50%;
        top: 0;

        @include devices(md) {
          left: 0;
          transform: translate(-3rem, -1.5rem);
        }
      }
    }

    .right {
      position: relative;
      flex: 1;

      .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        justify-content: center;
        padding: 2rem;
        height: 100%;
      }
    }
  }
}
